<template>
  <div class="container-inner mx-auto py-16 markdown-body">
    <router-view />
    <router-link :to="`/${$i18n.locale}/blog`">
      <input
        type="submit"
        name="book"
        :value="$t('pages.blog.back')"
        class="text-sm text-white tracking-wide font-semibold py-2 px-4 rounded-lg inline-flex shadow-lg items-center bg-green-500 hover:bg-green-600"
      />
    </router-link>
  </div>
</template>
<style src="../assets/css/github-markdown.css" />

<script>
export default {
  metaInfo() {
    return {
      title: this.$route.meta.title + " | MedInDiet",
      meta: [
        { name: "description", content: this.$route.meta.description },
        {
          name: "keywords",
          content: this.$route.meta.keywords,
        },
      ],
      link: [
        { rel: "canonical", href: "https://medindiet.com/" + this.$route.path },
        {
          rel: "alternate",
          hreflang: "el",
          href: "https://medindiet.com/" + this.$route.path,
        },
        /* {
          rel: "alternate",
          hreflang: "en",
          href: "https://medindiet.com/en/blog"
        } */
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Article",
            headline: this.$route.meta.title,
            author: "Dr. Stavroula Argyridou",
            publisher: {
              "@type": "Person",
              name: "Stavroula Argyridou",
              url: "https://medindiet.com/",
              image: "https://medindiet.com/img/stav_photo_small.jpg",
              sameAs: [
                "https://www.facebook.com/medinidiet",
                "https://twitter.com/SArgyridou",
                "https://www.linkedin.com/in/stavroula-argyridou",
                "https://www.instagram.com/medindiet/",
              ],
              jobTitle: "Clinical Dietitian-Nutritionist",
            },
            genre: "Δίαιτα, Διατροφή",
            keywords: this.$route.meta.keywords,
            wordcount: this.$route.meta.wordCount,
            url: "https://medindiet.com" + this.$route.path,
            datePublished: this.$route.meta.date,
            dateCreated: this.$route.meta.date,
            dateModified: this.$route.meta.date,
            description: this.$route.meta.description,
            image: "medindiet.com/img/" + this.$route.meta.img,
          },
        },
      ],
    };
  },
};
</script>
